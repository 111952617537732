<template>
    <div v-if="loadSuccess">
        <div class="task_container">
            <van-notice-bar  wrapable :scrollable="false"
                            :background="step === 0? '#fffbe8': '#fff'"
                            :color="step === 0? '#f60': '#aaa'"
                            text="请将手机定位到如下地址并打开大众点评"/>
            <van-cell>{{detail.short_name}}-{{detail.address}}</van-cell>
        </div>
        <div class="task_container" v-if="step > 0">
            <van-notice-bar  wrapable :scrollable="false"
                            :background="step === 1? '#fffbe8': '#fff'"
                            :color="step === 1? '#f60': '#aaa'"
                            text="请在大众点评搜索以下关键词"/>
            <van-cell>{{detail.key_word}}</van-cell>
        </div>
        <div class="task_container" v-if="step > 1">
            <van-notice-bar  wrapable :scrollable="false"
                            :background="step === 2? '#fffbe8': '#fff'"
                            :color="step === 2? '#f60': '#aaa'"
                            text="请在搜索结果中根据店铺头图找到此店铺"/>
            <van-card style="background: #fff;margin-top:0;" :thumb="detail.head_img">
                <div slot="title">
                    <span style="font-size: 16px;font-weight: bold;margin-right: 5px;">店铺名：&nbsp;****{{detail.shop_name_mask}}</span>
                </div>
                <template #tags>
                    <van-tag plain type="primary" style="margin-top: 10px;">{{detail.shop_type_name}}</van-tag>
                </template>
            </van-card>
        </div>
        <div class="task_container" v-if="step > 2">
            <van-notice-bar  wrapable :scrollable="false"
                            :background="step === 3? '#fffbe8': '#fff'"
                            :color="step === 3? '#f60': '#aaa'"
                            text="请输入店铺名或复制店铺链接"/>
            <van-cell-group>
                <van-field v-model="shop_name" label="店铺名称" :placeholder="step > 3?'店铺链接已认证通过，无需输入店铺名':'请输入完整的店铺名'"
                           :disabled="step > 3"/>
                <van-field v-model="link_url" label="店铺链接" :placeholder="step > 3?'店铺名已认证通过，无需输入链接':'请粘贴店铺链接'"
                           right-icon="question-o" :disabled="step > 3"
                           @click-right-icon="help"/>
            </van-cell-group>
        </div>
        <div class="task_container" v-if="step > 3">
            <van-notice-bar  wrapable :scrollable="false"
                            :background="step === 4? '#fffbe8': '#fff'"
                            :color="step === 4? '#f60': '#aaa'"
                            text="请在此店铺打卡"/>
                <div style="line-height: 24px;font-size: 14px;padding: 10px 16px;">
                    <span>打卡参考右侧示例图片</span>
                    <span style="float: right;color: red;text-decoration: underline;" @click="help(1)">示例</span>
                </div>
        </div>
        <div class="task_container" v-if="step > 4">
            <van-notice-bar  wrapable :scrollable="false">
                打卡后将在待点评页面出现该店铺信息，请提交待点评截图
            </van-notice-bar>
            <div>
                <van-cell-group>
                    <van-field label="待点评截图" right-icon="question-o">
                        <template slot="input" slot-scope="props">
                            <div style="display: flex;" @click="chooseImg">
                                <div v-if="clock_in_img" class="van-image van-uploader__preview-image" style="box-sizing: border-box;margin-right: 5px;">
                                    <van-image :src="clock_in_img" fit="fill" style="display: block;height: 80px;width: 80px;"/>
                                </div>
                                <div class="van-uploader__upload" v-else>
                                    <i class="van-icon van-icon-photograph van-uploader__upload-icon"></i>
                                    <div class="text" style="color: #646566;font-size: 12px;text-align: center;">点击上传</div>
                                </div>
                            </div>
                        </template>
                        <template #right-icon>
                            <span style="color: red;text-decoration: underline;" @click="help(2)">示例</span>
                        </template>
                    </van-field>
                </van-cell-group>
            </div>
        </div>
        <div style="width: 100%;padding:0 16px 50px;box-sizing: border-box;">
            <van-button color="linear-gradient(to right,#ff6034,#ee0a24)" style="border-radius: 22px;"
                        v-if="step < 3 || step === 4" :loading="loading" block @click="step++">已完成，下一步
            </van-button>
            <van-button color="linear-gradient(to right,#ff6034,#ee0a24)" style="border-radius: 22px;" block v-if="step === 3" disabled>
                店铺校验通过后才可进入下一步
            </van-button>
            <van-button color="linear-gradient(to right,#ff6034,#ee0a24)" style="border-radius: 22px;" v-if="step === 5"
                        :disabled="!permit"
                        :loading="loading" block @click="accept">确定接单
            </van-button>
        </div>
    </div>
</template>

<script>
    import {ImagePreview, Toast} from "vant";
    import wx from "weixin-js-sdk";
    import {Storage} from '../../utils/localstorage';

    export default {
        name: "TalentTask",
        data() {
            return {
                storage:{},
                loadSuccess: false,
                eo_id: '',
                detail: {},
                shop_name: '',
                link_url: '',
                loading: false,
                permit: false,
                step: 0,
                clock_in_img:'',
                clock_in_img_src:'',
            }
        },
        created() {
            this.eo_id = this.$route.query.eo_id;
            this.post('/api/client/shop/explore/task/detail', {eo_id: this.eo_id}).then(result => {
                if (result.code === 0) {
                    this.detail = result.data;
                    this.detail.shop_name = this.detail.shop_name.replace('(','（').replace(')','）');
                    this.loadSuccess = true;
                    if(this.storage.getItem('eo_id') !== this.eo_id){
                        this.storage.setItem({
                            name:'eo_id',
                            value:this.eo_id,
                            expires:5*60*1000, // 5分钟
                        });
                    }
                } else {
                    this.$dialog.alert({
                        title: '提示',
                        message: result.msg,
                    })
                }
            });

            this.storage = new Storage();
            // 验证用户身份,订单id
            if(this.storage.getItem('eo_id') === this.eo_id){
                // 检查是否储存了探店信息
                let step = this.storage.getItem('step');
                if(step){
                    this.step = step;
                }
                let shop_name = this.storage.getItem('shop_name');
                if(shop_name) {
                    this.shop_name = shop_name;
                }
                let link_url = this.storage.getItem('link_url');
                if(link_url) {
                    this.link_url = link_url;
                }
            } else {
                this.storage.removeItem('step');
                this.storage.removeItem('shop_name');
                this.storage.removeItem('link_url');
            }
        },
        mounted() {

        },
        watch: {
            shop_name(newValue) {
                newValue = newValue.replace('(','（').replace(')','）');
                if (this.detail.shop_name && newValue.toLowerCase() === this.detail.shop_name.toLowerCase()) {
                    this.$notify({type: 'success', message: '您填写的店铺名称已通过认证，请上传打卡图片并接单。'});
                    this.storage.setItem({
                        name:'shop_name',
                        value:newValue,
                        expires:60*60*1000, // 60分钟
                    })
                    this.step++;
                }
            },
            link_url(newValue) {
                let newName = newValue.split(',').shift();
                let shopName = this.detail.link_url.split(',').shift();
                if (newName.replace('(','（').replace(')','）') === shopName.replace('(','（').replace(')','）')) {
                    this.$notify({type: 'success', message: '您填写的店铺链接已通过认证，请上传打卡图片并接单。'});
                    this.storage.setItem({
                        name:'link_url',
                        value:newValue,
                        expires:60*60*1000, // 60分钟
                    })
                    this.step++;
                }
            },
            step(newValue){
                this.storage.setItem({
                    name:'step',
                    value:newValue,
                    expires:60*60*1000, // 60分钟
                })
            },
        },
        methods: {
            chooseImg() {
                let that = this;
                wx.chooseImage({
                    count: 1, // 默认9
                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (result) {
                        wx.uploadImage({
                            localId: result.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: function (res) {
                                // 保存服务器端ID
                                that.clock_in_img_src = res.serverId;
                                that.permit = true;
                                // 预览图片
                                if (/(Android)/i.test(navigator.userAgent)) {
                                    that.clock_in_img = result.localIds[0];
                                } else {
                                    // ios无法预览，需要base64编码
                                    wx.getLocalImgData({
                                        localId: result.localIds[0], // 图片的localID
                                        success: function (res) {
                                            that.clock_in_img = res.localData;
                                        }
                                    });
                                }
                            },
                            fail: function (res) {
                                Toast('图片选择失败');
                                console.log(res);
                            },
                        });
                    },
                    fail: function (res) {
                        that.post('/api/common/log', {chooseImageError: res}).then(result => {
                            Toast('图片选择失败');
                            console.log(result);
                        });
                    },
                });
            },
            help(index = 0) {
                let url = '';
                if(index === 1) {
                    url = 'http://lf-public.oss-cn-shenzhen.aliyuncs.com/explore/task1.png';
                } else if (index === 2) {
                    url =  'http://lf-public.oss-cn-shenzhen.aliyuncs.com/explore/task2.jpg';
                } else {
                    url =  'http://lf-public.oss-cn-shenzhen.aliyuncs.com/upload/shop_link.jpg';
                }
                ImagePreview({
                    images: [url],
                    closeable: true,
                    closeOnPopstate: true,
                });
            },
            accept() {
                this.loading = true;
                this.post('/api/client/shop/explore/accept', {eo_id: this.eo_id,clock_in_img_src:this.clock_in_img_src}).then(result => {
                    if (result.code === 0) {
                        this.loading = false;
                        this.$dialog.alert({
                            title: '接单成功',
                            message: '接单成功后3小时可以投稿，我们会在3小时后提醒您。',
                        }).then(() => {
                            this.$router.replace('/talent/mine');
                        })
                    } else {
                        this.loading = false;
                        this.$dialog.alert({
                            title: '提示',
                            message: result.msg,
                        });
                    }
                }).catch(res => {
                    this.loading = false;
                    this.$toast('服务器异常');
                });
            },
        }
    }
</script>

<style scoped>
    .task_container {
        margin: 20px 10px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 8px 0 rgba(232, 237, 250, .6), 0 2px 4px 0 rgba(232, 237, 250, .5);
    }
</style>